
export const projectsData = [
    {
        id: 1,
        image: "https://akoya.in/cdn/shop/files/d4.jpg?v=1723530368&width=1800",
        title: "Akoya",
        category: "Web",
        demo: 'https://akoya.in/'
    },
    {
        id: 2,
        image: "https://variety.com/wp-content/uploads/2020/05/netflix-logo.png",
        title: "Netflix Clone",
        category: "Web",
        demo: ""
    },
    {
        id: 3,
        image: "https://www.phonepe.com/static/new-user-video-fallback-4c206d0ca6cdace196686357238fdd8c.png",
        title: "PhonePe Payment Gateway",
        category: "Web",
        demo: "https://www.phonepe.com/business-solutions/payment-gateway/"
    },

    {
        id: 4,
        image: "https://akoya.in/cdn/shop/files/d3.jpg?v=1723530349&width=1800",
        title: "Akoya Mobile App",
        category: "App",
        demo: ""
    },

    {
        id: 5,
        image: "https://repository-images.githubusercontent.com/280387936/7df18000-cbcb-11ea-961a-3feddc6957e3",
        title: "Voice Assistant",
        category: "App",
        demo: ""
    },

    {
        id: 6,
        image: "https://www.freecodecamp.org/news/content/images/2024/04/1060---539.jpg",
        title: "Flutter Tutorial",
        category: "App",
        demo: ""
    },
];

export const projectsNav = [
    {
        name: "All",
    },
    {
        name: "Web",
    },
    {
        name: "App",
    },
    // {
    //     name: "C",
    // },
];
