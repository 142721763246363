import Image1 from "../../assets/woman1.png";
import Image2 from "../../assets/man.png";
import Image3 from "../../assets/woman2.png";

export const Data = [
    {
        id: 1,
        image: Image1,
        title: "Swara B",
        description: "Delivered exceptional design solutions that elevated the project's aesthetics.",
    },
    {
        id: 2,
        image: Image3,
        title: "Shital B",
        description: "Offered innovative ideas and optimized the workflow for better efficiency.",
    },
    {
        id: 3,
        image: Image2,
        title: "Pravin K",
        description: "Enhanced user experience by streamlining navigation and fixing critical issues.",
    },
];